import $store from '@/store'
import Vue from '@/main'

export const authNotRequiredPages = [
  'auth',
  'auth-new-password',
  'auth-forgot-password',
  'page-not-found',
  'forbidden',
]

export default async (to, from, next) => {
  if (!$store.state.user.token && !authNotRequiredPages.includes(to.name)) {
    return next({
      name: 'auth',
      query: {
        redirect: to.fullPath,
      },
    })
  }
  if (!authNotRequiredPages.includes(to.name)) {
    if (!$store.state.user.loaded) {
      try {
        await $store.dispatch('user/loadUser')
      } catch (error) {
        return next({
          name: 'auth',
          query: {
            redirect: to.fullPath,
          },
        })
      }
    }

    if (
      to.name === 'main' &&
      (Vue.$can(Vue.$ableRead, Vue.$abilitySubjects.CUSTOMERS) ||
        $store.getters['user/isLoggedAsCustomer'])
    ) {
      return next({
        name: 'customers',
      })
    }

    // for logged customers
    if (
      $store.getters['user/isLoggedAsCustomer'] &&
      !Vue.$can(Vue.$ableRead, Vue.$abilitySubjects.CUSTOMERS)
    ) {
      if (
        to.name === 'customers' ||
        (to.params.customerId &&
          to.params.customerId !== $store.state.user.profile.id)
      ) {
        return next({
          name: 'customer-overview',
          params: {
            customerId: $store.state.user.profile.id,
          },
        })
      }
    }

    if (to.meta.permission && !Vue.$can(...to.meta.permission)) {
      return next({
        name: 'forbidden',
        params: { pathMatch: to.path.split('/').slice(1) },
        query: to.query,
        hash: to.hash,
      })
    }
  }
  return next()
}
